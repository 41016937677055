<template>
  <div class="tw-z-20 tw-w-full tw-h-[60px] tw-fixed tw-left-0 tw-top-0 tw-border-b">
    <nav class="tw-h-full hstack tw-space-x-4 is-mobile navbar">
      <TheDrawer />
      <a href="/">
        <img src="@/images/asacom_logo_mark.png" class="tw-w-8 is-block" alt="" />
      </a>
      <div id="teleport-target-page-title"></div>
      <!-- TODO: こちらも <div id="teleport-target-page-title"></div> 利用するようにしていきたい -->
      <div v-if="pageTitle" class="is-size-5">{{ pageTitle }}</div>
      <div class="is-ml-auto hstack tw-space-x-5">
        <BuNotification
          v-if="isTrial"
          :type="currentUser.companyAdmin ? 'is-warning' : undefined"
          role="alert"
          class="tw-text-xs p-2 is-hidden-touch m-0"
        >
          朝コムを活用できていますか？
          <br />
          <strong>
            <a v-if="currentUser.companyAdmin" :href="companiesCompanyServicePlanPath()">
              {{ trialMessage }}
            </a>
            <span v-else>{{ trialMessage }}</span>
          </strong>
        </BuNotification>
        <!-- NOTE: バッジ表示のために右に余白が必要 -->
        <div class="hstack pr-5 tw-space-x-4">
          <TheChatNotification :unread-count="unreadChatCount" />
          <TheNotification :unread-count="unreadNotificationCount" />
        </div>
      </div>
    </nav>
  </div>
</template>

<script lang="ts">
import { provideApolloClient } from '@vue/apollo-composable'
import TheChatNotification from '@/src/containers/TheChatNotification.vue'
import TheNotification from '@/src/containers/TheNotification.vue'
import { useCurrentUser, useUnreadChatCount, useUnreadNotificationCount, useExpo, useBadgin } from '@/src/hooks'
import { getApolloClient } from '@/src/lib/apollo'
import { companiesCompanyServicePlanPath } from '@/src/lib/rails-routes'

export default defineComponent({
  components: {
    TheChatNotification,
    TheNotification,
  },
  props: {
    isTrial: {
      type: Boolean,
      required: true,
    },
    remainingDays: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    provideApolloClient(getApolloClient())

    const currentUser = useCurrentUser()
    const { pageTitle } = window.APP.rails
    const trialMessage = computed<string>(() => {
      if (props.remainingDays < 0) {
        return 'トライアル期間が終了しています。'
      }

      return `あと${props.remainingDays}日でトライアル期間が終了します。`
    })
    const unreadChatCount = useUnreadChatCount(toRef(currentUser, 'id'))
    const unreadNotificationCount = useUnreadNotificationCount(toRef(currentUser, 'id'))
    const unreadTotalCount = computed<number>(() => unreadNotificationCount.value + unreadChatCount.value)
    useExpo(unreadTotalCount)
    useBadgin(unreadTotalCount)

    return {
      currentUser,
      pageTitle,
      trialMessage,
      companiesCompanyServicePlanPath,
      unreadChatCount,
      unreadNotificationCount,
    }
  },
})
</script>
