<script lang="ts" setup>
import { sortBy } from '@/src/lib/collections/sortBy'
import { normalizeSearchString } from '@/src/lib/stringUtils'

export type Item = {
  title: string
  url: string
  avatarPath?: string
}

type State = {
  query: string
}

const props = withDefaults(
  defineProps<{
    loading: boolean
    items: readonly Item[]
    disabled?: boolean
    tooltip?: string
  }>(),
  {
    disabled: false,
  },
)

defineEmits<{
  show: []
}>()

const state = reactive<State>({
  query: '',
})
const sortedItems = computed(() => sortBy(props.items, ({ title }) => title))
const filteredItems = computed<readonly Item[]>(() => {
  const normalizedQuery = normalizeSearchString(state.query)
  return sortedItems.value.filter(({ title }) => normalizeSearchString(title).includes(normalizedQuery))
})
</script>

<template>
  <VDropdown :disabled="disabled" data-test-selector="page-title-dropdown-menu" @show="$emit('show')">
    <VTooltip :content="tooltip" :disabled="!tooltip || disabled">
      <BuButton type="is-ghost" class="has-text-dark has-text-weight-normal !tw-no-underline px-1">
        <div class="hstack">
          <div class="tw-max-w-24 sm:tw-max-w-56 tw-truncate">
            <slot />
          </div>
          <span v-if="!disabled" class="icon">
            <iconify-icon icon="bi:chevron-down" inline />
          </span>
        </div>
      </BuButton>
    </VTooltip>
    <template #popper>
      <div class="sm:tw-w-xs">
        <div class="dropdown-item">
          <!-- TODO: 自動フォーカスしたいがrefで参照できないので後回し、、 -->
          <input v-model="state.query" class="input is-small" type="search" placeholder="絞り込み" />
        </div>
        <div v-if="loading" class="dropdown-item tw-min-h-20">
          <div class="is-flex is-justify-content-center">
            <SpinnerIcon />
          </div>
        </div>
        <div v-else class="tw-max-h-sm tw-overflow-y-auto">
          <a v-for="item of filteredItems" :key="item.url" class="dropdown-item hstack tw-space-x-2" :href="item.url">
            <UserAvatar v-if="item.avatarPath" :src="item.avatarPath" type="small"></UserAvatar>
            <span class="tw-truncate">{{ item.title }}</span>
          </a>
        </div>
      </div>
    </template>
  </VDropdown>
</template>
