<script lang="ts">
export type OnActive = () => void
</script>

<script lang="ts" setup>
import type { OnClick } from '@/src/components/CalendarBox.vue'
import CalendarBox from '@/src/components/CalendarBox.vue'
import { useBoolean } from '@/src/hooks/useBoolean'
import { currentPlainJSTDate } from '@/src/lib/dateTimeUtils'
import type { VueComponentProps } from '@/src/types/vueUtil'

type CalendarBoxProps = VueComponentProps<typeof CalendarBox>

const props = withDefaults(
  defineProps<{
    onClickDate: OnClick
    onActive?: OnActive
    morningDates: readonly string[]
    eveningDates: readonly string[]
    onChangeMonth: CalendarBoxProps['onChangeMonth']
    startDate: string
  }>(),
  {
    startDate: currentPlainJSTDate().toString(),
  },
)

const [isOpen, setIsOpen] = useBoolean(false)

// NOTE: CalendarBoxのマウントを初回クリックまで遅延させるため
const handleFirstClick = (): void => {
  setIsOpen.on()
  props.onActive && props.onActive()
}

const handleClickDate: OnClick = (date) => {
  setIsOpen.off()
  props.onClickDate(date)
}
</script>

<template>
  <div class="calendar-button">
    <!-- eslint-disable-next-line vue/no-v-model-argument -->
    <VDropdown v-model:shown="isOpen" paddingless>
      <!-- NOTE: VDropdownのslotに直接VTooltipを指定すると上手く動作しないので一段divでラップしている  -->
      <div>
        <VTooltip content="カレンダーを見る">
          <BuButton type="is-ghost" class="has-text-dark" @click.once="handleFirstClick">
            <iconify-icon icon="bi:calendar3" />
          </BuButton>
        </VTooltip>
      </div>
      <template #popper>
        <CalendarBox
          v-if="isOpen"
          :on-click="handleClickDate"
          :morning-dates="morningDates"
          :evening-dates="eveningDates"
          :on-change-month="onChangeMonth"
          :start-date="startDate"
        />
      </template>
    </VDropdown>
  </div>
</template>
