<script lang="ts" setup>
const ICONS = {
  sun: {
    icon: 'bi:sun-fill',
    class: 'tw-text-orange-500',
  },
  moon: {
    icon: 'bi:moon-stars-fill',
    class: 'tw-text-blue-800',
  },
  fire: {
    icon: 'fa-solid:fire',
    class: 'tw-text-red-500',
  },
  up: {
    icon: 'bi:graph-up',
    class: 'tw-text-red-500',
  },
  down: {
    icon: 'bi:graph-down',
    class: 'tw-text-teal-300',
  },
  stopwatch: {
    icon: 'fa-solid:stopwatch',
    class: 'tw-text-blue-400',
  },
  laptopHouse: {
    icon: 'fa-solid:laptop-house',
    class: 'tw-text-orange-600',
  },
}

type Icons = keyof typeof ICONS

const props = defineProps<{
  icon: Icons
}>()

const iconInfo = computed(() => ICONS[props.icon])
</script>

<template>
  <iconify-icon :icon="iconInfo.icon" :class="iconInfo.class"></iconify-icon>
</template>
