<script lang="ts" setup>
import { provideApolloClient } from '@vue/apollo-composable'
import { useMobileScreen } from '@/src/hooks'
import { useLazyCurrentUserChats } from '@/src/hooks/useCurrentUserChats'
import { getApolloClient } from '@/src/lib/apollo'
import { myChatsPath } from '@/src/lib/rails-routes'

defineProps<{
  unreadCount: number
}>()

provideApolloClient(getApolloClient())

const { chats, loading, refetch, enable } = useLazyCurrentUserChats()
const isMobile = useMobileScreen()
const isEmpty = computed(() => chats.value.length === 0)

const handleShow = (): void => {
  enable()
  refetch()
}
</script>

<template>
  <div>
    <a v-if="isMobile" class="has-text-black" data-test-selector="unread-chat-badge" :href="myChatsPath()">
      <IconWithBadge role="button" :count="unreadCount">
        <iconify-icon icon="bi:chat" />
      </IconWithBadge>
    </a>
    <VDropdown v-else paddingless @show="handleShow">
      <div data-test-selector="unread-chat-badge">
        <IconWithBadge role="button" :count="unreadCount">
          <iconify-icon icon="bi:chat" />
        </IconWithBadge>
      </div>
      <template #popper>
        <NotificationContainer :is-loading="loading" :is-empty="isEmpty" is-always-show-header>
          <template #header>
            <a :href="myChatsPath()"> すべて見る </a>
          </template>
          <ChatNotificationItem v-for="chat in chats" :key="chat.id" :chat="chat" />
          <template #empty> 未読のチャットはありません。 </template>
        </NotificationContainer>
      </template>
    </VDropdown>
  </div>
</template>
