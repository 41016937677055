<template>
  <VTooltip v-if="likes.length > 0" class="icon-tooltip" data-test-selector="like-counter">
    <template #content>
      <div v-for="like in displayedLikes" :key="like.id">{{ like.user.name }}</div>
      <div v-if="otherLikeCount > 0">他{{ otherLikeCount }}人...</div>
    </template>
    <BuButton type="is-ghost" size="is-small" @click="setOpen.on">
      <span class="tag is-small" :class="liked ? 'has-background-primary-light' : 'is-light'">
        <iconify-icon icon="twemoji:thumbs-up" inline />
        <span v-if="likes.length > 1" class="has-text-grey ml-1">{{ likes.length }}</span>
      </span>
    </BuButton>
    <Teleport to="body">
      <BModal v-model="isOpen" width="300px">
        <div class="card">
          <div class="card-content tw-space-y-3">
            <div v-for="like in sortedLikes" :key="like.id" class="hstack">
              <UserAvatar :src="like.user.avatarPath" type="small" />
              <span class="ml-2">{{ like.user.name }}</span>
            </div>
          </div>
        </div>
      </BModal>
    </Teleport>
  </VTooltip>
</template>

<script lang="ts">
import { gql } from '@apollo/client/core'
import type { PropType } from 'vue'
import type { LikeForLikeCounterFragment } from '@/src/graphql/operations'
import { useBoolean } from '@/src/hooks/useBoolean'
import { sortBy } from '@/src/lib/collections/sortBy'

gql`
  fragment likeForLikeCounter on Like {
    id
    user {
      id
      name
      avatarPath
    }
  }
`

const TOOLTIP_LIMIT = 10

export default defineComponent({
  props: {
    likes: {
      type: Array as PropType<readonly LikeForLikeCounterFragment[]>,
      required: true,
    },
    currentUserId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const [isOpen, setOpen] = useBoolean(false)
    const liked = computed(() => props.likes.some(({ user }) => user.id === props.currentUserId))
    const sortedLikes = computed(() => sortBy(props.likes, ({ user }) => (user.id === props.currentUserId ? 0 : 1)))
    const displayedLikes = computed(() => sortedLikes.value.slice(0, TOOLTIP_LIMIT))
    const otherLikeCount = computed(() => Math.max(props.likes.length - TOOLTIP_LIMIT, 0))

    return { isOpen, setOpen, displayedLikes, otherLikeCount, liked, sortedLikes }
  },
})
</script>
