import { gql } from '@apollo/client/core'
import { useMutation } from '@vue/apollo-composable'
import { CreateLikeDocument, DeleteLikeDocument } from '@/src/graphql/operations'
import { validateMutationResult } from '@/src/lib'

gql`
  mutation createLike($chatMessageId: ID!) {
    createLike(input: { chatMessageId: $chatMessageId }) {
      result {
        ... on Like {
          id
          chatMessage {
            id
            likes {
              id
              ...likeForLikeCounter
            }
          }
        }
        ... on ValidationErrors {
          errors {
            message
          }
        }
      }
    }
  }

  mutation deleteLike($chatMessageId: ID!) {
    deleteLike(input: { chatMessageId: $chatMessageId }) {
      chatMessage {
        id
        likes {
          id
          ...likeForLikeCounter
        }
      }
    }
  }
`

type Like = (chatMessageId: string) => Promise<boolean>
type Unlike = (id: string) => Promise<void>

const useLike = () => {
  const { mutate } = useMutation(CreateLikeDocument)

  const like: Like = async (chatMessageId) => {
    const result = await mutate({ chatMessageId })
    return validateMutationResult(result?.data?.createLike?.result)
  }

  return like
}

const useUnlike = () => {
  const { mutate } = useMutation(DeleteLikeDocument)

  const unlike: Unlike = async (chatMessageId) => {
    await mutate({ chatMessageId })
  }

  return unlike
}

type ToggleLike = (chatMessageId: string, liked: boolean) => Promise<void>

export const useLikes = (): ToggleLike => {
  const like = useLike()
  const unlike = useUnlike()

  const toggleLike: ToggleLike = async (chatMessageId, liked) => {
    await (liked ? unlike(chatMessageId) : like(chatMessageId))
  }

  return toggleLike
}
